<template>
  <div class="orderTable">
    <el-table
      :data="myData"
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#fafafa',
        color: '#000000',
        'font-weight': 400,
      }"
      class="elTable"
      ref="elTable"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="createTime" label="收样时间" width="200">
        <template slot-scope="scope">
          <span>{{ $utils.map.parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="bpOrderNo" label="样本编号"> </el-table-column>
      <el-table-column prop="sampleName" label="名称"> </el-table-column>
      <el-table-column prop="batchNo" label="样品批号"> </el-table-column>
      <el-table-column prop="count" label="数量"> </el-table-column>
      <el-table-column prop="trait" label="样品性状"> </el-table-column>
      <el-table-column prop="purity" label="纯度"> </el-table-column>
      <el-table-column prop="specs" label="样品规格"> </el-table-column>
      <el-table-column prop="storageConditions" label="贮存条件">
      </el-table-column>
      <el-table-column prop="salesman" label="快递单号"> </el-table-column>
      <el-table-column prop="salesman" label="订单编号"> </el-table-column>
      <el-table-column prop="salesman" label="合同编号"> </el-table-column>
      <el-table-column prop="operation" label="操作" width="200">
        <template slot-scope="scope">
          <operation :data="scope.row" v-on="$listeners" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import operation from "./operation.vue";
import { order } from "@/utils/dict";
export default {
  components: {
    operation,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
    };
  },
  methods: {
    // 获取状态name
    getStatusName(value) {
      const { status } = order;
      let name;
      status.forEach((item) => {
        if (item.value === value) {
          name = item.name;
          return;
        }
      });
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.elTable {
  margin-top: 25px;
  .demoInfo {
    color: $theme_color;
    cursor: pointer;
    align-items: center;
  }
}

/*样式穿透*/
.elTable ::v-deep .cell {
  overflow: visible;
}
</style>
