<template>
  <div class="operation">
    <span class="item" v-if="submitStatus.includes(status)" @click="submit">
      <i class="iconfont el-icon-my-tijiao"></i
      ><span class="title">提交</span></span
    >
    <span
      class="item"
      v-if="viewReportStatus.includes(status)"
      @click="previewReport"
    >
      <i class="iconfont el-icon-my-xiangxi"></i
      ><span class="title">预览报告</span></span
    >
    <span class="item" @click="viewDetail">
      <i class="iconfont el-icon-my-xiangxi"></i
      ><span class="title">查看</span></span
    >
    <el-dropdown @command="handleCommand">
      <span class="more">
        更多<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-if="confirmReportStauts.includes(status)"
          :command="{ dropdownItem: 'confirmReport' }"
          >确认报告</el-dropdown-item
        >
        <el-dropdown-item
          v-if="downloadReportStauts.includes(status)"
          :command="{ dropdownItem: 'downloadReport' }"
          >下载报告</el-dropdown-item
        >
        <el-dropdown-item
          v-if="confirmReportStauts.includes(status)"
          :command="{ dropdownItem: 'applyAfterSale' }"
          >申请售后</el-dropdown-item
        >
        <el-dropdown-item
          v-if="submitStatus.includes(status)"
          :command="{ dropdownItem: 'modify' }"
          >修改</el-dropdown-item
        >
        <el-dropdown-item :command="{ dropdownItem: 'copy' }"
          >复制</el-dropdown-item
        >
        <el-dropdown-item :command="{ dropdownItem: 'entrustProtocol' }"
          >委托协议</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 预览报告 -->
    <el-dialog center :visible.sync="dialogViewReport">
      <apt-pdf :src="pdfUrl" />
    </el-dialog>
    <!-- 委托协议 -->
    <el-dialog center :visible.sync="dialogEntrustProtocol">
      <apt-pdf :src="myData.contractUrl" />
    </el-dialog>
    <!-- 查看订单详情 -->
    <el-dialog center :visible.sync="dialogDetail" width="1080px">
      <create :data="detailData" />
    </el-dialog>
    <!-- 确认报告 -->
    <el-dialog title="报告确认单" center :visible.sync="dialogConfirmReport">
      <div class="dialogConfirmReport">
        <p class="start">上海中科新生命生物科技有限公司</p>
        <p class="start pl">
          我单位委托贵公司的技术服务(报告编号：{{
            confirmReportParams.reportNo
          }})报告电子版已经收悉，并经过我方就报告内容认真审读，核对后作出如下确认：
        </p>
        <p class="start pl">
          我单位认可贵公司为本次服务编制的报告,并接受本报告电子版内
          容,现委托贵司收阅本函后尽快出具纸质版报告并交付我方!
        </p>
        <p class="start pl">
          确认方单位名称：{{ confirmReportParams.orgName }}
        </p>
        <p class="start pl">日期：{{ confirmReportParams.confirmDate }}</p>
        <div class="agree_wrap">
          <div class="agree">
            <el-checkbox v-model="isSelectedConfirmReport"
              >我已审阅报告并确认无异议</el-checkbox
            >
          </div>
        </div>
        <div class="step_btn">
          <el-button @click="handleSubmitConfirmReport">提交</el-button>
          <el-button type="primary" @click="dialogConfirmReport = false"
            >关闭</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 申请售后 -->
    <el-dialog
      title="申请售后"
      center
      :visible.sync="dialogApplyAfterSale"
      width="500px"
    >
      <el-form
        :rules="applyAfterSaleRule"
        :model="applyAfterSaleForm"
        label-width="100px"
        class="applyAfterSaleForm"
        ref="applyAfterSale"
      >
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="applyAfterSaleForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="uploadImg">
          <el-upload
            list-type="picture-card"
            :on-success="
              (response, file, fileList) => updateFile(file, fileList)
            "
            :on-remove="(file, fileList) => updateFile(file, fileList)"
            class="TheortSeqImg"
            multiple
            :limit="5"
            :headers="uploadHeaders"
            :data="uploadData"
            :action="uploadAction"
            :accept="uploadAccept"
          >
            <i class="el-icon-plus add"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleApplyAfterSale" type="primary"
            >确定</el-button
          >
          <el-button @click="dialogApplyAfterSale = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  previewReport,
  copyBsgOtdOrder,
  getConfirmReport,
  submitReportConfirmation,
  downloadReport,
  afterSales,
} from "@/api/order/index";
import create from "@/views/components/order/create/create.vue";
export default {
  components: {
    create,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
      viewReportStatus: [15, 17, 18, 19], // 可以预览报告的状态
      submitStatus: [20], //可以提交、修改的状态
      confirmReportStauts: [15], //可以确认报告、申请售后的状态
      downloadReportStauts: [18, 19], //可以下载报告的状态
      dialogViewReport: false, //查看报告弹窗
      dialogDetail: false, //查看详情弹窗
      dialogEntrustProtocol: false, //查看委托协议弹窗
      dialogConfirmReport: false, //查看确认报告弹窗
      pdfUrl: "", //pdf地址
      detailData: {}, //查看详情弹窗参数
      dialogConfirmReport: false, //确认报告弹窗
      isSelectedConfirmReport: false, //确认报告协议勾选
      confirmReportParams: {
        confirmDate: "",
        orgName: "",
        reportNo: "",
      }, //确认报告参数
      dialogApplyAfterSale: false, //申请售后弹窗
      applyAfterSaleForm: {
        remark: "", //备注
        img: [], //上传图片
      }, //申请售后表单
      applyAfterSaleRule: {
        remark: [
          { required: true, message: "请填写备注", trigger: "blur" },
          {
            min: 5,
            max: 300,
            message: "长度在 5 到 300 个字符",
            trigger: "blur",
          },
        ],
      }, //申请售后校验规则
      //上传文件请求头
      uploadHeaders: {
        Authorization: this.$utils.storage.get("user").token,
      },
      //上传图片参数
      uploadData: {
        type: 0,
      },
      //上传图片路径
      uploadAction: "",
      uploadAccept: "image/jpeg,image/jpg,image/png,image/gif", //图片上传格式
    };
  },
  computed: {
    status() {
      return this.myData.status;
    },
  },
  methods: {
    // 获取上传图片接口地址
    handleGetUploadPath() {
      getUploadPath(this.uploadData).then((data) => {
        this.uploadAction = data;
      });
    },
    // 加载申请售后
    handleApplyAfterSale() {
      this.$refs.applyAfterSale.validate((valid) => {
        if (valid) {
          this.applyAfterSale();
        }
      });
    },
    // 申请售后
    applyAfterSale() {
      const imgs = this.applyAfterSaleForm.img.join(",");
      const params = {
        url: imgs, //附件图片
        orderNo: Number(this.myData.id), //订单id
        reason: this.applyAfterSaleForm.remark, //备注
        businessType: this.$utils.map.getUserBstpNo(),
      };
      afterSales(params).then((res) => {
        this.dialogApplyAfterSale = false;
        this.$emit("handleGetOrderLsit");
        this.$message({
          type: "success",
          message: "申请售后提交成功",
        });
      });
    },
    // 图片上传成功回调
    updateFile(file, fileList) {
      const imgUrls = [];
      console.log(fileList);
      fileList.forEach((item) => {
        //fileList里包含了返显数据，需要判断
        if (item.response && item.response.data && item.response.data.path) {
          imgUrls.push(item.response.data.path);
        }
      });
      this.applyAfterSaleForm.img = imgUrls.join(",");
    },
    // 下载报告
    handleDownloadReport() {
      this.$messageBox
        .confirm("此操作将下载报告, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = {
            orderNo: Number(this.myData.id),
            operationType: 2, //操作类型；1：预览。2：下载。
            businessType: this.$utils.map.getUserBstpNo(),
          };
          downloadReport(params).then((res) => {
            window.open(res);
            this.$message({
              type: "success",
              message: "下载报告成功",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消复制",
          });
        });
    },
    // 确认报告弹窗
    confirmReport() {
      this.dialogConfirmReport = true;
      const params = {
        orderId: Number(this.myData.id),
        businessType: this.$utils.map.getUserBstpNo(),
      };
      getConfirmReport(params).then((res) => {
        this.confirmReportParams = res;
      });
    },
    // 提交确认报告
    submitConfirmReport() {
      const params = {
        orderId: Number(this.myData.id),
        businessType: this.$utils.map.getUserBstpNo(),
      };
      submitReportConfirmation(params).then((res) => {
        this.dialogConfirmReport = false;
        this.$message({
          type: "success",
          message: "确认报告提交成功",
        });
      });
    },
    // 加载提交确认报告
    handleSubmitConfirmReport() {
      if (this.isSelectedConfirmReport) {
        this.submitConfirmReport();
      } else {
        this.$message({
          type: "error",
          message: "请先勾选审阅报告确认按钮",
        });
      }
    },
    // 选中更多选项
    handleCommand(row) {
      if (row.dropdownItem === "modify") {
        this.submit();
      } else if (row.dropdownItem === "copy") {
        this.copy();
      } else if (row.dropdownItem === "entrustProtocol") {
        this.entrustProtocol();
      } else if (row.dropdownItem === "confirmReport") {
        this.confirmReport();
      } else if (row.dropdownItem === "downloadReport") {
        this.handleDownloadReport();
      } else if (row.dropdownItem === "applyAfterSale") {
        this.dialogApplyAfterSale = true;
      }
    },
    // 预览报告
    previewReport() {
      const params = {
        orderId: Number(this.myData.id),
      };
      // previewReport(params).then((res) => {
      //   this.pdfUrl = res;
      //   this.dialogViewReport = true;
      // });
    },
    // 提交订单
    submit() {
      this.$router.push({
        path: "/order/create",
        query: {
          type: "submitAgain",
          id: this.myData.id,
        },
      });
    },
    // 查看订单详情
    viewDetail() {
      this.detailData = {
        id: this.myData.id,
        disabled: true,
      };
      this.dialogDetail = true;
    },
    // 展开复制弹窗
    copy() {
      this.$messageBox
        .confirm("此操作将复制该订单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = {
            orderId: this.myData.id,
          };
          copyBsgOtdOrder(params).then((res) => {
            this.$message({
              type: "success",
              message: "复制订单成功，请前往草稿箱查阅",
            });
            this.$emit("handleGetOrderLsit");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消复制",
          });
        });
    },
    // 委托协议
    entrustProtocol() {
      this.dialogEntrustProtocol = true;
    },
  },
  created() {
    this.handleGetUploadPath();
  },
};
</script>

<style lang="scss" scoped>
.operation {
  color: $theme_color;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    .title,
    .iconfont {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }
    .iconfont {
      width: 16px;
      height: 18px;
    }
  }
  .more {
    cursor: pointer;
    color: $theme_color;
    font-size: 14px;
  }
  .dialogViewReport {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
    border: $theme_color;
  }
  .dialogConfirmReport {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
    border: $theme_color;
    flex-wrap: wrap;
    .elSteps {
      width: 70%;
    }
    .agree_wrap {
      @include center;
      width: 100%;
      .agree {
      }
    }
    .nextStep {
      border: 1px solid $theme_color;
      width: 200px;
      height: 50px;
      @include center;
      cursor: pointer;
    }
    .start {
      justify-content: start;
      width: 100%;
    }
    .pl {
      padding-left: 28px;
    }
  }
}

/*样式穿透*/
.applyAfterSaleForm ::v-deep .add {
  font-size: 28px;
  color: #8c939d;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
}

.applyAfterSaleForm ::v-deep .TheortSeqImg {
  .el-upload-list--picture-card,
  .el-upload-list__item,
  .el-upload--picture-card {
    width: 94px;
    height: 94px;
  }
  .el-upload--picture-card {
    line-height: 94px;
  }
}
</style>
