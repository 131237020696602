<template>
  <div class="index">
    <apt-page-tab v-model="active"></apt-page-tab>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      active: "/demo/demo", //当前激活页
    };
  },
};
</script>

<style lang="scss" scoped></style>
