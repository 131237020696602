<template>
  <div class="queryForm">
    <el-row>
      <apt-select-search
        :selectValue="queryParams"
        :selectList="queryParamsList"
        @search="search"
        class="queryParams"
      />
    </el-row>
  </div>
</template>

<script>
import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      myData: this.data,
      status: order.status, //订单状态列表
      queryParamsList: [
        {
          name: "样本编号",
          value: "样本编号",
        },
        {
          name: "样本编号2",
          value: "样本编号2",
        },
      ],
      queryParams: "样本编号",
    };
  },
  methods: {
    search(val) {
      console.log(val);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.queryForm {
  .search {
  }
}
</style>
