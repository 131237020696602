<template>
  <div class="order elTab">
    <el-tabs v-model="active" @tab-click="handleClick" class="el-tabs">
      <el-tab-pane label="我的样本" name="/demo/demo"></el-tab-pane>
    </el-tabs>
    <query-form :data="form" @handleGetOrderLsit="handleGetOrderLsit" />
    <order-table :data="tableData" @handleGetOrderLsit="handleGetOrderLsit" />
    <div class="elPagination">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import orderTable from "./orderTable.vue";
import queryForm from "./queryForm.vue";
import { getBsgOtdOrderList } from "@/api/order/index";
export default {
  name: "order",
  components: {
    orderTable,
    queryForm,
  },
  data() {
    return {
      checked: false,
      active: "/demo/demo",
      form: {
        type: "", //参数类型
        value: "", // 参数值
      },
      tableData: [], //订单列表
      pageSizes: [12, 24, 36], //分页数量
      pageSize: 12, //分页数量
      total: 100, //数据总计
      pageNum: 1, //当前页
    };
  },
  methods: {
    //获取草稿订单列表
    handleGetOrderLsit(row) {
      console.log(row);
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        //TEST
        // type: this.form.type,
        // value: this.form.value,
      };
      getBsgOtdOrderList(params).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },
    // 改变页数
    handleCurrentChange(val) {
      this.pageNum = val;
      this.handleGetOrderLsit();
    },
    // 改变每页数量
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleGetOrderLsit();
    },
    handleClick(tab, event) {
      // this.$router.push(`${this.active}`);
    },
  },
  created() {
    this.handleGetOrderLsit();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.order {
  background: #ffffff;
  margin: 15px 25px;
  border-radius: 3px;
  min-height: 600px;
  padding: 0 20px 90px;
  position: relative;
  /*样式穿透*/
  .elPagination {
    @include end;
    position: absolute;
    bottom: 25px;
    right: 25px;
  }
  .el-tabs ::v-deep .el-tabs__item {
    font-size: 18px;
  }
}
</style>
